import { HeaderParagraph } from "./HeaderParagraph"
import { MainBanner } from "./MainBanner"
import { Policies } from "./Policies"
import { PolicyDetail } from "./PolicyDetail"
import { TextBoxOnFullWidthImg } from "./TextBoxOnFullWidthImg"
import { StatsSlider } from "./StatsSlider"
import { BannerImage } from "./BannerImage"
import { HeaderInWhiteBox } from "./HeaderInWhiteBox"
import { HeaderAndList } from "./HeaderAndList"
import { TwoInfoBoxesWithIconsLightBkgd } from "./TwoInfoBoxesWithIconsLightBkgd"
import { LatestNewsComp } from "./LatestNews"
import { TipsForDonating } from "./TipsForDonating"
import { WhenWhereToDonate } from "./WhenWhereToDonate"
import { HeaderImgagesAndBtn } from "./HeaderImgagesAndBtn"
import { HeaderOneImgAndBtn } from "./HeaderOneImgAndBtn"
import { Supporters } from "./Supporters"
import { Team } from "./Team"
import { FourBoxes } from "./FourBoxes"
import { VideoComponent } from "./VideoComponent"
import { TwoBoxesWithBgimg } from "./TwoBoxesWithBgimg"
import { TextBlock } from "./TextBlock"
import { TwoImgsWithBtns } from "./TwoImgsWithBtns"
import { QuoteSlider } from "./QuoteSlider"
import { ContactPage } from "./ContactPage"
import { ThreeBoxes } from "./ThreeBoxes"
import { LeftImage } from "./LeftImage"
import { TwoBoxesWithoutBgimg } from "./TwoBoxesWithoutBgimg"
import { BorderBox } from "./BorderBox"
import { WhiteBoxWithBgimg } from "./WhiteBoxWithBgimg"
import { Wishlist } from "./Wishlist"
import { ShopBanner } from "./ShopBanner"
import { ShopProducts } from "./ShopProducts"
import { ExternalContactForm } from "./ExternalContactForm"

export const ACFPageComponents = {
  BannerImage: BannerImage,
  HeaderParagraph: HeaderParagraph,
  ImageAndTextSlider: MainBanner,
  Policies: Policies,
  PolicyDetail: PolicyDetail,
  TextBoxOnFullWidthBkgdImg: TextBoxOnFullWidthImg,
  FeaturedStatsSlider: StatsSlider,
  HeaderInWhiteBox: HeaderInWhiteBox,
  HeaderAndList: HeaderAndList,
  TwoInfoBoxesWithIconsLightBackground: TwoInfoBoxesWithIconsLightBkgd,
  TipsForDonating: TipsForDonating,
  WhenWhereToDonate: WhenWhereToDonate,
  LatestNews: LatestNewsComp,
  HeaderImgagesAndBtn: HeaderImgagesAndBtn,
  HeaderOneImgAndBtn: HeaderOneImgAndBtn,
  Supporters: Supporters,
  Team: Team,
  FourBoxes: FourBoxes,
  VideoComponent: VideoComponent,
  TwoBoxesWithBgimg: TwoBoxesWithBgimg,
  TextBlock: TextBlock,
  TwoImgsWithBtns: TwoImgsWithBtns,
  QuoteSlider: QuoteSlider,
  ContactPage: ContactPage,
  ThreeBoxes: ThreeBoxes,
  LeftImage: LeftImage,
  TwoBoxesWithoutBgimg: TwoBoxesWithoutBgimg,
  BorderBox: BorderBox,
  WhiteBoxWithBgimg: WhiteBoxWithBgimg,
  Wishlist: Wishlist,
  ShopBanner: ShopBanner,
  ShopProducts: ShopProducts,
  ExternalContactForm: ExternalContactForm,
}
